import React from "react";
import ReactModal from "react-modal";

import Input from "../shared/input";

import CinemaYes from "./modal-forms/modal-cinemaYes";
import CinemaNo from "./modal-forms/modal-cinemaNo";
import ContentOwnerYes from "./modal-forms/modal-contentOwnerYes";
import ContentOwnerNo from "./modal-forms/modal-contentOwnerNo";


import "./modal.scss";

ReactModal.setAppElement('#root');

class Modal extends React.Component {

    screenID = 0;
    state = {
        success: false,
        didSubmit: false,
        formData: {
            user: "",
            account: false,

            name: "",
            lastname: "",
            phone: "",
            email: "",

            company: "",
            street: "",
            postcode: "",
            city: "",
            country: "",
            vat: "",

        },
      };

    handleChange = (property, value) => {
        const formData = { ...this.state.formData, [property]: value };
        this.setState({ formData });
    }


    render() {
        const { showModal, handleCloseModal } = this.props;

        const commonFieldProps = {
            handleChange : this.handleChange,
            didSubmit    : this.state.didSubmit,
            formData     : this.state.formData,
        };

        const{ user, account } = this.state.formData;

        let form;

        switch ( user ) {
            case "cinema":
                if ( account === "yes" ) {
                    form = <CinemaYes />;
                }
                else if ( account === "no" ) {
                    form = <CinemaNo />;
                }
                break;

            case "owner":
                if ( account === "yes" ) {
                    form = <ContentOwnerYes />;
                }
                else if ( account === "no" ) {
                    form = <ContentOwnerNo />;
                }
                break;

            default:
                break;
        }

        return (
            <ReactModal
                className="ReactModal"
                overlayClassName="ReactModalOverlay"
                isOpen={showModal}
                onRequestClose={handleCloseModal}>
                    <div className="Modal">
                        <button className="Modal__Close" onClick={handleCloseModal}>Close</button>
                        <div className="Modal__Options">
                            <span>Cinema or Content owner?</span>
                            <Input {...commonFieldProps}
                                id="modal-user"
                                type="select"
                                options={{
                                    empty: "---",
                                    cinema: "Cinema",
                                    owner: "Content Owner",
                                }}
                            />
                            <span className="Modal__Options-space">Do you have an account?</span>
                            <Input {...commonFieldProps}
                                id="modal-account"
                                type="select"
                                options={{
                                    empty: "---",
                                    yes: "Yes",
                                    no: "No",
                                }}
                            />
                        </div>

                        <div>
                            {form}
                        </div>
                    </div>
            </ReactModal>
        )
    }
}

export default Modal;
