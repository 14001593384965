import React from "react";
import Axios from "axios";

import Input from "../../shared/input";

import ReCaptcha from "../../recaptcha/recaptcha.js";

import "../modal.scss";

class ContentOwnerYes extends React.Component {
    state = {
        didSubmit: false,
        verifiedReCaptcha: false,
        formData: {
            name: "",
            lastname: "",
            phone: "",
            email: "",
        },
    }

    handleChange = (property, value) => {
        const formData = { ...this.state.formData, [property]: value };
        this.setState({ formData });
    }

    handleSubmit = (e) => {
        this.setState({ didSubmit: true });

        // Check if all properties of form data has a value
        const isValid = Object.values(this.state.formData).reduce((isValid, value) => {
            return isValid ? !!value : false;
        }, true);

        if(!isValid){
            e.preventDefault();
            return;
        }

        Axios.post(CINIO_API + "/emails/content", this.state.formData)
            .then(() => this.setState({ success: true }))
            .catch((e) => alert(e.response.data.message));
    }

    handleVerifyReCaptcha = (verified) => {
        this.setState({ verifiedReCaptcha: verified });
    }


    render() {
        const commonFieldProps = {
            handleChange : this.handleChange,
            didSubmit    : this.state.didSubmit,
            formData     : this.state.formData
        };

        const verifiedReCaptcha = this.state.verifiedReCaptcha ? "verifiedReCaptcha" : "";

        return(
            <div className="Modal__Yes">
                <form>
                    <h3>Content owner/Contact info</h3>
                    <Input {...commonFieldProps}
                        id="modal-name"
                        placeholder="John"
                    />
                    <Input {...commonFieldProps}
                        id="modal-lastname"
                        placeholder="Doe"
                    />
                    <Input {...commonFieldProps}
                        id="modal-phone"
                        type="tel"
                        placeholder="+31 0612345678"
                    />
                    <div className="Modal__Yes--space">
                        <Input {...commonFieldProps}
                            id="modal-email"
                            type="email"
                            placeholder="hello@example.com"
                        />
                    </div>
                    <ReCaptcha handleVerifyReCaptcha={this.handleVerifyReCaptcha}/>
                    <button className={ `send-btn ${verifiedReCaptcha}` } type="submit" onClick={this.handleSubmit} disabled={!this.state.verifiedReCaptcha}>send</button>
                </form>
            </div>
        );
    }
}

export default ContentOwnerYes;
