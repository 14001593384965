import React from "react";
import "./about-us.scss";

const Aboutus = () => {
    return (
        <div className="About">
            <div className="About__content">
                <div className="About__title">What's Cinio? <br /> </div>
                <div className="About__info">
                    The digitalization of cinema changed the movie industry forever. Cinemas are now able to screen an unimaginable variety of content. This caused the meteoric rise of Alternative Content: cinema classics, world cinema, opera, ballet, concerts, sports, live-events and much more.
                    <br /><br />
                    Cinio is the independent marketplace where everything comes together. Cinemas now have a comprehensive overview of all available alternative content.
                    <br /><br />
                    Booking content has never been easier.
                    <br /><br />
                    Cinio enables cinemas to freely decide when, how and what they want to screen; while a distributor can offer their content to cinemas all over the world.
                    <br /><br />
                    We manage all aspects for distributors; and give cinemas the tools to manage their total package.
                    <br /><br />
                    Combined with the Gofilex ODD Network, Cinio provides a flexible, trusted, order-driven flow of content.
                </div>
            </div>
        </div>
    );
}

export default Aboutus;
