import React            from "react";
import Axios            from "axios";

import Input            from "../shared/input";
import ContactSuccess   from "./contact-success";
import Modal            from "../modal/modal";

import ECA              from "../assets/logo-eca.png";
import CinemaTech       from "../assets/cinema-tech.png";

import ReCaptcha from "../recaptcha/recaptcha.js";

import "./contact.scss";

class Contact extends React.Component {

    reCaptchaRef = React.createRef();

    state = {
        success: false,
        didSubmit: false,
        verifiedReCaptcha: false,
        formData: {
            name: "",
            email: "",
            subject: "",
            organization: "",
            message: "",
        },
        showModal: false,
    };
    handleChange = (property, value) => {
        const formData = { ...this.state.formData, [property]: value };
        this.setState({ formData });
    }

    handleSubmit = () => {
        this.setState({ didSubmit: true });

        // Check if all properties of form data has a value
        const isValid = Object.values(this.state.formData).reduce((isValid, value) => {
            return isValid ? !!value : false;
        }, true);

        if(!isValid){
            return;
        }
        Axios.post(CINIO_API + "/emails/contact", this.state.formData)
            .then(() => this.setState({ success: true }))
            .catch((e) => alert(e.response.data.message));
    }

    handleOpenModal = () => {
        this.setState({ showModal: true });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    handleVerifyReCaptcha = (verified) => {
        this.setState({ verifiedReCaptcha: verified });
    }

    render() {
        const { handleNavClick } = this.props;

        const commonFieldProps = {
            handleChange : this.handleChange,
            didSubmit    : this.state.didSubmit,
            formData     : this.state.formData
        };

        const verifiedReCaptcha = this.state.verifiedReCaptcha ? "verifiedReCaptcha" : "";

        if (this.state.success) {
            return <ContactSuccess />;
        }

        return (
            <div className="Contact">
                <div className="Contact__empty-space"></div>
                <div className="Contact__request-button">
                    <button className="req-btn"
                        onClick={this.handleOpenModal}>
                        Request Cinio account
                    </button>
                </div>
                <div className="Contact__form">
                    <div className="Contact__form-left">
                        <div className="Contact__form-field">
                            <Input {...commonFieldProps}
                                id="name"
                                label="What's your name?"
                                placeholder="John Doe"
                            />
                            <Input {...commonFieldProps}
                                id="email"
                                label="What's your email?"
                                placeholder="hello@example.com"
                            />
                            <Input {...commonFieldProps}
                                id="subject"
                                label="What's the subject?"
                                placeholder="Joining Cinio"
                            />
                            <Input {...commonFieldProps}
                                id="organization"
                                label="What's your organization?"
                                placeholder="Gofilex"
                            />
                            <ReCaptcha handleVerifyReCaptcha={this.handleVerifyReCaptcha}/>
                        </div>
                    </div>
                    <div className="Contact__form-right">
                        <div className="Contact__form-field">
                            <Input {...commonFieldProps}
                                id="message"
                                type="textarea"
                                label="What's your message?"
                                placeholder="Start typing your message"
                            />
                        </div>
                        <button className={ `Contact__button ${verifiedReCaptcha}` }
                                disabled={!this.state.verifiedReCaptcha}
                                onClick={this.handleSubmit}>
                                Send message
                        </button>
                        <Modal
                            showModal={this.state.showModal}
                            handleCloseModal={this.handleCloseModal}
                            />
                    </div>
                </div>
                <div className="Contact__up" onClick={() => handleNavClick("home")}>Home</div>
                <div className="Contact__footer">
                    <a href="https://eventcinemaassociation.org/" target="_blank"><img src={ECA} className="Contact__footer-logos--cinema--eca" /></a>
                    <a href="https://www.cinematech.today/" target="_blank"><img src={CinemaTech} className="Contact__footer-logos--cinema" /></a>
                </div>
            </div>
        );
    }
}

export default Contact;
