import React from "react";

import Reaptcha from "reaptcha";

import "./recaptcha.scss"

class ReCaptcha extends React.Component {
    reCaptchaRef = React.createRef();

    state = {
        loaded: false,
        verified: false,
    }

    onLoad = () => {
        this.setState({
          captchaReady: true,
          loaded: true,
        });
      };

    onVerify = () => {
        this.props.handleVerifyReCaptcha(true);
        this.setState({ verified: true });
    }

    render() {
        const { loaded } = this.state;

        return (
            <div>
                { loaded && <label>Please verify you're human</label> }
                <Reaptcha
                    ref={e => (this.reCaptchaRef = e)}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onLoad={this.onLoad}
                    onVerify={this.onVerify}
                    theme="dark"

                />
            </div>
        );
    }
}

export default ReCaptcha;
