import React from "react";

import Contact from "../contact/contact.js";


// const ContactSuccess = () => {
//     return (

//         <div className="Contact">
//             <div className="Contact__success">
//                 <h2>Thank you!</h2>
//                 <p>We have received your message, and will get back to you as soon as possible.</p>
//             </div>
//         </div>
//     );
// };

// export default ContactSuccess;

class ContactSuccess extends React.Component {

    test = () => {
        return (
            <Contact />
        )
    }

    render() {

        return (
            <div className="Contact">
                <div className="Contact__success">
                    <h2>Thank you!</h2>
                    <p>We have received your message, and will get back to you as soon as possible.</p>
                    <button type="button" onClick={this.test}>click</button>
                </div>
            </div>
        );
    }
}

export default ContactSuccess;
