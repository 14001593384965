import React from "react";

import Input from "../../shared/input";

class ScreensSeats extends React.Component {

    state = {
        didSubmit: false,
        formData: {
            user: "",
            account: false,
            screenAdd: "",
            seatsAdd: "",
        },
      };

    handleChange = (property, value) => {
        const index = this.props.screenArray.findIndex(screen => this.props.id === screen.id);
        this.props.screenArray[index][property] = value;
    }

    render(){
        const commonFieldProps = {
            handleChange : this.handleChange,
            didSubmit    : this.state.didSubmit,
            formData     : this.state.formData
        };
        // console.log(this.props);

        return(
            <div className="Modal__screensAndSeats">
                <Input {...commonFieldProps}
                    id="modal-name"
                    placeholder="Screen name"
                />
                <span>
                    <p>Seats:</p>
                    <Input {...commonFieldProps}
                        id="modal-seats"
                        placeholder="0"
                    />
                </span>
                <button className="Modal__screensAndSeats__Remove" onClick={this.props.delete} type="button">remove</button>
            </div>
        );
    }
}
export default ScreensSeats;
