import React from 'react';

import StickyButton from "./components/sticky-button/sticky-button";
import Header       from "./components/header/header";
import Aboutus      from "./components/about-us/about-us";
import Team         from "./components/team/team";
import Cases        from "./components/cases/cases";
import Faq          from "./components/FAQ/faq";
import Contact      from "./components/contact/contact";
import Gofilex      from "./components/logo-gofilex/gofilex";

class App extends React.Component {

    homeRef    = React.createRef();
    aboutRef   = React.createRef();
    teamRef    = React.createRef();
    casesRef   = React.createRef();
    faqRef     = React.createRef();
    contactRef = React.createRef();

    scrollToRef = (id) => {
        const refKey = id + "Ref";
        const ref = this[refKey] && this[refKey].current;

        if (ref) {
            window.scrollTo(0, ref.offsetTop);
        }
    }

    render() {
        return (
            <div className="App">
                <StickyButton handleClick={this.scrollToRef} title="Join Cinio" />
                <Gofilex />
                <div ref={this.homeRef}><Header handleNavClick={this.scrollToRef} /></div>
                <div ref={this.aboutRef}><Aboutus /></div>
                <div ref={this.teamRef}><Team /></div>
                <div ref={this.casesRef}><Cases scrollToContactForm={() => this.scrollToRef("contact")} /></div>
                <div ref={this.faqRef}><Faq /></div>
                <div ref={this.contactRef}><Contact handleNavClick={this.scrollToRef} /></div>
            </div>
        );
    }
}

export default App;
