import React from "react";

const CaseCard = (props) => {
    const {
        id,
        title,
        subtitle,
        description,
        openCard,
        toggleOpenCard
    } = props;

    const isActive = id === openCard
        ? "is-active"
        : "";

    return (
        <div className={`Cases__card Cases__card--${id} ${isActive}`}
            onClick={() => toggleOpenCard(id)}>
            <div className="Cases__card-block">
                <div className="Cases__card-title">{title}</div>
                <div className="Cases__card-subtitle">{subtitle}</div>
                <div className="Cases__card-description">{description}</div>
            </div>
        </div>
    );
}

export default CaseCard;
