import React from "react";
import "./team.scss";

const Team = () => {
    return (
        <div className="Team">
            <div className="Team__content">
                <div className="Team__title">Meet the <br /> Cinio Team</div>
                <div className="Team__info">
                    Cinio is the platform created by Gofilex, the trusted, independent Dutch E-delivery company. <br /><br />
                    Because of the decades-long experienced team at Gofilex, films find their way to the silver screen easier than ever before. <br /><br />
                    Cinio’s team consists of highly experienced industry-veterans and tech-savvy young professionals. Combined with the industry knowledge of Gofilex, our dedicated team of professionals will always be ready to assist you.
                </div>

            </div>
        </div>
    );
}

export default Team;
