import React from "react";

const formatId = (id) => {
    const splits = id.split("-");
    switch(splits.length) {
        case 1:
            return id;

        default:
            splits.shift();
            return splits.join("-");
    }
}

const selectInput = (options, inputProps) => {
    return <select {...inputProps}>
                {Object.entries(options).map(([key, value])=> <option key={key} value={key}>{value}</option> )}
            </select>;
}

const Input = (props) => {
    let { id, label, placeholder, handleChange, formData, didSubmit, defaultValue, options, value, handleChecked, type } = props;
    const formattedId = formatId(id);

    value = formData[formattedId];
    if ( !value ) {
        value = defaultValue;
    }

    const hasError = !value && didSubmit ? "has-error" : "";

    const inputProps = { value, placeholder, onChange: (e) => handleChange(formattedId, e.target.value) };

    let inputField;
    switch (type) {
        case "textarea":
            inputField = <textarea {...inputProps} />;
            break;

        case "select":
            inputField = selectInput(options, inputProps);
            break;

        case "button":
            inputField = <button {...inputProps} />;
            break;

        case "checkbox":
            inputField = <input type="checkbox" onChange={handleChecked} id="checkbox"/>
            break;

        default:
            inputField = <input type={type || "text"} {...inputProps} />;
            break;
    }

    return (
        <div className={hasError}>
            { label && <label htmlFor={id}>{label}</label> }
            {inputField}
        </div>
    )
};

export default Input;
