import React from "react";

import "../modal.scss";

class ContentOwnerNo extends React.Component {
    render() {
        return(
            <div className="Modal__Info">
                <p>Please get in touch with us via <br className="Modal__info__hide Modal__info__show"/>
                <span className="Modal__Info__mail">cinio@gofilex.nl</span> or call us at <br className="Modal__info__hide Modal__info__show"/>
                <span className="Modal__Info__num">+31 (0)30 6070 869</span></p>
            </div>
        );
    }
}

export default ContentOwnerNo;
