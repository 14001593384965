import React from "react";
import "./sticky-button.scss";

const StickyButton = (props) => {
    return (
        <div className="StickyButton" onClick={() => props.handleClick("contact")}>
             {props.title}
        </div>
    );
}

export default StickyButton;
