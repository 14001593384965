import React from "react";
import bgimg from "../assets/background-seats.png";
import faqData from "./faq-data";
import Masonry from "../masonry/masonry";

import "./faq.scss";

const Faq = () => {
    return (
        <div className="Faq" style={{backgroundImage: `url(${bgimg})`}}>
            <div className="Faq__title">FAQ</div>
            <Masonry maxColumnWidth={500}>
                {faqData.map((qa) => (
                    <div className="Faq__qa">
                        <h3 className="Faq__question">{qa.q}</h3>
                        <p className="Faq__answer" dangerouslySetInnerHTML={{ __html: qa.a }} />
                    </div>
                ))}
            </Masonry>
        </div>
    );
}

export default Faq;
