import React from "react";
import CaseCard from "./case-card";

import eventsBkg from "./events.png";
import moviesBkg from "./movies.png";
import theaterBkg from "./theater.png";

import "./cases.scss";

class Cases extends React.Component {

    state = {
        openCard: ""
    };

    toggleOpenCard = (clickedCard) => {
        const openCard = clickedCard === this.state.openCard
            ? ""
            : clickedCard;

        this.setState({ openCard });
    }

    render() {
        const hasActiveChild = this.state.openCard ? "has-active-child" : "";
        const commonProps    = { toggleOpenCard: this.toggleOpenCard, openCard: this.state.openCard };

        return (
            <div className={`Cases ${hasActiveChild}`}>
                <div className="Cases__title">On Cinio</div>
                <div className="Cases__cards">
                    <CaseCard {...commonProps}
                        id="theater"
                        title="Events"
                        subtitle="Event Cinema and Alternative Content"
                        description="Event cinema/alternative content is currently the fastest growing genre in the worldwide cinema market. On Cinio, you will find a broad overview of all events: theatre, opera, ballet, concerts and more. For the first time ever, cinemas have a comprehensive overview of all available alternative content. Combined with the extremely flexible booking that Cinio provides, a new era for event cinema has begun."
                    />
                    <CaseCard {...commonProps}
                        id="movies"
                        title="Films"
                        subtitle="Back Catalogue and Non-Premier Content"
                        description="Cinio features a wide range of back-catalogue films from distributors all over the world. Finally, cinemas can easily find and book non-premier content; distributors can effortlessly offer their entire catalogue to cinemas world-wide."
                    />
                    <CaseCard {...commonProps}
                        id="events"
                        title="Live"
                        subtitle="Live Cinema"
                        description="Gofilex has always been at the forefront of live-streaming content in cinemas. We have streamed sports events; e-gaming; red-carpet events; live theatre, opera, and ballet; live concerts and much more. All of which can now be found on Cinio. Because of this, booking live events has been extremely simplified. By globally connecting distributors and cinemas, live cinema will keep growing."
                    />
                </div>
                <div className="Cases__btn" onClick={this.props.scrollToContactForm}>Join Cinio</div>
            </div>
        )
    }
}

export default Cases;
