import React, { createRef } from "react";
import "./masonry.scss";

class Masonry extends React.Component {

    container = createRef().current;

    state = {
        columns: 3,
        didMount: false,
        isMobile: isMobile()
    };

    componentDidMount() {
        // Make sure component is rendered before calculating columns
        setTimeout(() => {
            this.setState({ didMount: true });
            this.updateColumnCount();
        }, 100);

        // Update columns on window resize
        window.addEventListener("resize", this.updateColumnCount);
    }

    getClosestParentWidth() {
        let el = this.container;
        let width = el ? el.clientWidth : 0;
        let attempts = 0;

        while (el && el.parentElement && !width && attempts < 10) {
            el = el.parentElement;
            width = el.clientWidth;
            attempts ++;
        }

        return width;
    }

    updateColumnCount = () => {
        if (!this.state.didMount || !this.container) {
            return;
        }
        const columns = Math.ceil(this.getClosestParentWidth() / this.props.maxColumnWidth);
        this.setState({
            columns: columns > 0 ? columns : 1
        });
    }

    getChildren = () => {
        const columnsCount = this.state.isMobile ? 2 : this.state.columns;
        const columns = new Array(columnsCount).fill(null).map(() => []);

        if (!Array.isArray(this.props.children)) {
            return null;
        }

        return this.props.children.reduce((columns, child, i) => {
            const index = i % columnsCount;
            columns[index].push(child);
            return columns;
        }, columns);
    }

    render() {
        const columnWidth = 100 / this.state.columns + "%";
        const grid        = this.props.grid + "rem";
        const padding     = `0 ${grid} ${grid} 0`;
        const children    = this.state.didMount ? this.getChildren() : [];

        if (!children) {
            return null;
        }

        return (
            <div className="Masonry" ref={(a) => { if (a) { this.container = a; }}}>
                {children.map((col, ci) => (
                    <div key={ci}
                        className="Masonry__column"
                        style={{ width: columnWidth }}>
                        {col.map((child, i) => {
                            return (
                                <div className="Masonry__element"
                                    style={{ padding }}
                                    key={i}>
                                    {child}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }
}

/**
 * Function for checking whether device is mobile
 */
export const isMobile = () => {
    const isMobileEl = document.querySelector("#is-mobile");

    if (!isMobileEl) {
        return false;
    }

    const style = window.getComputedStyle(isMobileEl);
    return style.display !== "none";
};

export default Masonry;
