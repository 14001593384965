import React, { useState, useEffect } from "react";
import "./header.scss";

import Video from "./cinio.mp4";

const Header = (props) => {
    const { handleNavClick } = props;

    const windowHeight = window.innerHeight;
    const cutoffSize = 0.15;

    const getVideoWidth = () => (windowHeight * 2) + (windowHeight * (cutoffSize * 2));
    const getMarginTop  = () => window.innerHeight * -cutoffSize;
    const getMarginLeft = () =>  (window.innerWidth - videoWidth) / 2;

    const [videoWidth, setVideoWidth] = useState(getVideoWidth());
    const [marginTop, setMarginTop] = useState(getMarginTop());
    const [marginLeft, setMarginLeft] = useState(getMarginLeft());

    const handleResize = () => {
        setVideoWidth(getVideoWidth());
        setMarginTop(getMarginTop());
        setMarginLeft(getMarginLeft());
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="header">
            <div className="header__video">
                <div className="header__overlay" />
                    <div className="header__video-wrapper">
                        <video src={Video}
                            width={videoWidth}
                            style={{ marginTop, marginLeft }}
                            autoPlay type="video/mp4"
                            muted={true}
                            loop={true}
                            poster={"https://www.w3schools.com/w3css/img_lights.jpg"}>
                        </video>
                    </div>
                    <div className="header__nav">
                        <div className="header__nav-item"
                            onClick={() => handleNavClick("home")}>
                            | Home
                        </div>
                        <div className="header__nav-item"
                            onClick={() => handleNavClick("about")}>
                            What’s Cinio
                        </div>
                        <div className="header__nav-item"
                            onClick={() => handleNavClick("team")}>
                            Meet the team
                        </div>
                        <div className="header__nav-item"
                            onClick={() => handleNavClick("cases")}>
                            On Cinio

                        </div>
                        <div className="header__nav-item"
                            onClick={() => handleNavClick("faq")}>
                            FAQ
                        </div>
                        <div className="header__nav-item"
                            onClick={() => handleNavClick("contact")}>
                            Contact
                        </div>
                    </div>
                </div>
            <div>
            </div>
        </div>
    );
}

export default Header;
