const faqData = [
    {
        q: "Who is Cinio for?",
        a: "Cinio is the marketplace for content-owners and cinemas."
    },
    {
        q: "What can I find on Cinio?",
        a: "Cinemas can find a plethora of available Alternative Content. Cinio is designed to be the overview of Alternative Content from all over the world, available for cinemas all over the world."
        + " As a cinema you can also find what content is trending; content recommended for you; live-events such as e-sports, operas, ballets, sports, concerts; recorded live-events; and a gigantic catalog of films, all directly available for cinemas."
        + " As a distributor, you can easily offer your content to many cinemas worldwide. Cinio will manage everything. When opening Cinio, you get a comprehensive overview of all orders placed for your content, and what content you’re currently offering through Cinio."
    },
    {
        q: "Who can I contact/talk to about Cinio?",
        a: "You can contact:"
        + "<br>Robbin; robbin@gofilex.nl - <p>+31 6 101 376 27</p>"
        + "<br>Timo; timo@gofilex.nl - <p>+31 6 244 677 00</p>"
        + "<br>Harry; harry@gofilex.de - <p>+49 176 110 850 08</p>"
        + "<br>Or you can send an e-mail to <p>cinio@gofilex.nl</p>"
    },
    {
        q: "What is Alternative Content?",
        a: "Alternative Content (sometimes called event cinema) refers to the use of cinema theatres to display a varied range of recorded entertainment excluding"
        + " traditional films, such as sports, operas, musicals, ballet, music, one-off TV  specials and current affairs. It also includes non-premiere films; older films that have already had one theatre run."
    },
    {
        q: "Will there be any subscription charges?",
        a: "No. Cinio is the open and free-to-use marketplace. By not utilizing subscriptions, our users while giving them full control, we’re ensuring that Cinio will always be a platform available to everyone."
    },
    {
        q: "If I book content through Cinio, what will happen next?",
        a: "The content will be delivered to you through the Gofilex ODD Network. Because of this we can ensure fast, flexible, trusted and on-time delivery."
    },
    {
        q: "What is the Gofilex ODD Network and how do I get connected?",
        a: "The content will be delivered to you through the Gofilex ODD Network. Because of this we can ensure fast, flexible, trusted and on-time delivery."
    },
];

export default faqData;
