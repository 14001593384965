import React from "react";
import Axios from "axios";

import Input from "../../shared/input";
import ScreensSeats from "./modal-screenSeats";

import ReCaptcha from "../../recaptcha/recaptcha.js";

import "../modal.scss";

class CinemaNo extends React.Component {

    reCaptchaRef = React.createRef();

    screenID = 0;
    state = {
        didSubmit: false,
        didChecked: false,
        verifiedReCaptcha: false,
        note: true,

        formData: {
            name: "",
            lastname: "",
            phone: "",
            email: "",
            company: "",
            street: "",
            postcode: "",
            city: "",
            country: "",
            vat: "",
            companyDelivery: "",
            countryDelivery: "",
            postcodeDelivery: "",
            cityDelivery: "",
            streetDelivery: "",
            screenArray: [],
        },
    }

    handleChange = (property, value) => {
        const formData = { ...this.state.formData, [property]: value };
        this.setState({ formData });
    }

    handleSubmit = (e) => {
        this.setState({ didSubmit: true });

        // Check if all properties of form data has a value
        const isValid = Object.values(this.state.formData).reduce((isValid, value) => {
            return isValid ? !!value : false;
        }, true);

        if(!isValid){
            e.preventDefault();
            return;
        }

        Axios.post(CINIO_API + "/emails/cinema/No", this.state.formData)
            .then(() => this.setState({ success: true }))
            .catch((e) => alert(e.response.data.message));
    }

    deleteScreen = (index) => {
        const copyScreenArray = Object.assign([], this.state.formData.screenArray);
        copyScreenArray.splice(index, 1);
        this.setState({
            formData: {
                ...this.state.formData,
                screenArray: copyScreenArray,
            }
        });
    }

    addScreen = () => {
        this.setState({ note: "" });

        this.screenID = this.screenID + 1;
        const copyScreenArray = Object.assign([], this.state.formData.screenArray);
        copyScreenArray.push({
            id: this.screenID,
            name: "",
            seats: 0,
        });
        this.setState({
            formData: {
                ...this.state.formData,
                screenArray: copyScreenArray,
            }
        });
    }

    handleChecked = () => {
        const isChecked = !this.state.didChecked;

        if(isChecked === true) {
            this.setState( {
                formData: {
                    ...this.state.formData,
                    companyDelivery: this.state.formData.company,
                    countryDelivery: this.state.formData.country,
                    postcodeDelivery: this.state.formData.postcode,
                    cityDelivery: this.state.formData.city,
                    streetDelivery: this.state.formData.street,
                }
            } );
        }

        this.setState({didChecked: !this.state.didChecked});
    }

    Note () {
        return (
            <p className="howToAddScreens">Press + to add screens.</p>
        )
    }

    handleVerifyReCaptcha = (verified) => {
        this.setState({ verifiedReCaptcha: verified });
    }

    render() {

        const commonFieldProps = {
            handleChange        : this.handleChange,
            didSubmit           : this.state.didSubmit,
            formData            : this.state.formData,
            handleChecked       : this.handleChecked,
        };

        const verifiedReCaptcha = this.state.verifiedReCaptcha ? "verifiedReCaptcha" : "";

        return(
        <form>
            <div className="Modal__No">
                    <div className="Modal__No__Left">
                        <div>
                            <h3>Cinema/Contact info</h3>
                            <Input {...commonFieldProps}
                                id="modal-name"
                                placeholder="John"
                            />
                            <Input {...commonFieldProps}
                                id="modal-lastname"
                                placeholder="Doe"
                            />
                            <Input {...commonFieldProps}
                                id="modal-phone"
                                type="tel"
                                placeholder="+31 0612345678"
                            />
                            <Input {...commonFieldProps}
                                id="modal-email"
                                type="email"
                                placeholder="hello@example.com"
                            />
                        </div>

                        <div className="Modal__No__Left__spacing">
                            <h3>Invoice Address</h3>
                            <Input {...commonFieldProps}
                                id="modal-company"
                                placeholder="Company"
                            />
                            <Input {...commonFieldProps}
                                id="modal-country"
                                placeholder="Country"
                            />
                            <Input {...commonFieldProps}
                                id="modal-postcode"
                                placeholder="Postcode"
                            />
                            <Input {...commonFieldProps}
                                id="modal-city"
                                placeholder="City"
                            />
                            <Input {...commonFieldProps}
                                id="modal-street"
                                placeholder="Street + nr"
                            />
                            <Input {...commonFieldProps}
                                id="modal-vat"
                                placeholder="VAT-nr"
                            />
                        </div>

                        <div className="Modal__No__Left__checkbox">
                            <div className="Modal__No__Left__checkbox--checkboxDiv">
                                <Input {...commonFieldProps}
                                    id="modal-checkbox"
                                    type="checkbox"
                                />
                            </div>
                            <button className="Modal__No__Left__checkbox--button" type="button">
                                <label className="Modal__No__Left__checkbox--label" id="label" htmlFor="checkbox">
                                    copy Invoice Address
                                </label>
                            </button>
                        </div>

                        <div className="Modal__No__Left__spacing">
                            <h3>Delivery Address</h3>
                            <Input {...commonFieldProps}
                                id="modal-companyDelivery"
                                placeholder="Company"
                            />
                            <Input {...commonFieldProps}
                                id="modal-countryDelivery"
                                placeholder="Country"
                            />
                            <Input {...commonFieldProps}
                                id="modal-postcodeDelivery"
                                placeholder="Postcode"
                            />
                            <Input {...commonFieldProps}
                                id="modal-cityDelivery"
                                placeholder="City"
                            />
                            <Input {...commonFieldProps}
                                id="modal-streetDelivery"
                                placeholder="Street + nr"
                            />
                        </div>
                    </div>

                    <div className="Modal__No__Middle">
                        <div>
                            <span className="Modal__No__Middle__Title">
                                <h3>number of screens</h3>
                                <p className="Modal__No__Middle__Title__Number">{this.state.formData.screenArray.length}</p>
                                <button className="Modal__screensAndSeats__Add" type="button" onClick={this.addScreen}></button>
                            </span>
                                {this.state.note ? this.Note() : null}

                            {
                                this.state.formData.screenArray.map((screen, index)=>{
                                    return(
                                        <ScreensSeats screenArray={this.state.formData.screenArray} key={screen.id} id={screen.id} delete={this.deleteScreen.bind(this, index)}/>
                                    );
                                })
                            }
                        </div>
                    </div>

                    <div className="Modal__No__Right">
                        <ReCaptcha handleVerifyReCaptcha={this.handleVerifyReCaptcha}/>
                        <button className={ `send-btn ${verifiedReCaptcha}` } type="submit" onClick={this.handleSubmit} disabled={!this.state.verifiedReCaptcha}>send</button>
                    </div>
            </div>
        </form>
        );
    }
}

export default CinemaNo;
