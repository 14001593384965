import React from "react";
import "./gofilex.scss";

import Logo from "../../assets/logo-gofilex.png";

const Gofilex = () => {
    return (
        <div className="Gofilex">
            <a href="https://www.gofilex.com/" target="_blank">
                <img src={Logo} />
            </a>
        </div>
    );
}

export default Gofilex;
